<template>
    <div>
       <div class="header">
             <span @click="gouot"></span>
             <span>销户证明</span>
             <span @click="islogout">退出</span>
        </div>

        <div class="music-t">
            <music />
        </div>
        
         <div class="content">
            <div class="content-up">
                <p>销户证明：</p>
            
                  <div v-if="!isWechat">
                    <van-uploader v-model="fileList1" :after-read="uploadershang" :before-delete="deleteimg1" preview-size="100%" :max-count="1">
                        <img style="width:100%" src="../../assets/zhanwu.jpg" alt="">
                    </van-uploader>
                </div>
                <div v-else>
                    <img v-if="!proveUrl" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'xiaohu')">
                
                    <div v-else style="width:100%">
                        <van-image class="vanImage" style="width:100%"  :src="proveUrl" @click="bigImg(proveUrl)" >
                            <van-icon class="iconX" name="close" @click="delImgHan('xiaohu')" />
                        </van-image>
                    </div>
                </div>
            </div>
        </div>
        <div>
             <van-field label="车牌号：" v-model="isData.carNumberPlate" />
             <van-field label="Vin：" v-model="isData.vin" />
        </div>
        <van-button class="content-btn" type="info" size="large" @click="submit">完成</van-button>
         <van-popup  :closeable="true"  v-model="voiceShow" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">是否语音播报</p>
              <hr>
              
              <div class="btn-ok">
                  <van-button @click="voiceShow= false">取消</van-button>
                  <van-button type="info" @click="yuying">确定</van-button>
              </div>
        </van-popup>
        <audio :src="voice" id="audio"  ref="au"></audio>
         <van-popup
            v-model="CarInfoShow"
            closeable
            :close-on-click-overlay="false"
            position="bottom"
            :style="{height: '100%',width:'100%'}">
            <div class="title_car">车辆列表</div>
            <ObtainCarInfo ref="ObtainRef" :repetition="repetition" @colosepopup="colosepopup" :vin="isData.identificationCode" @comeWei="comeWei" @upDate="upDate" />
            
        </van-popup>
    </div>
</template>

<script>
import { Toast } from 'vant';
import { is_post } from "../../Http/api.js";
import { deleteCookie } from '@/utils/env'
import music from '../../components/music.vue'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import ObtainCarInfo from '@/components/ObtainCarInfo'
import {PlateNum} from '@/api/carInfo'
    export default {
        components: {
            music,ObtainCarInfo
        },
         data() {
            return {
                fileList1: [],
                isData:{},
                imageUrl:"",
                voiceShow:false,
                voice:'',
                longClick:0,
                timeOutEvent:0,
                fileList2:[],
                VinUrl:"",
                vindata:"",
                wximagelocalids:[],//判断上传张数
                toast:null,//转圈圈
                proveUrl:'',//销户
                CarInfoShow:false,
                repetition:[]
            }
        },
        mounted () {
            if(this.isWechat){

                this.wcConfig();
            }
        },
        watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        methods: {
             isPlay(n){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                let text = `车牌号：${n.carNumberPlate},车架号：${n.vin}`
                this.$http.post('index.php/wechat/makeYuYin',{text}).then(res=>{
                    
                    this.voice = res.data.data
                    toast.clear()//清除加载效果
                    var u = navigator.userAgent;
                    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if(isiOS){
                        this.voiceShow = true
                    }else{
                        this.$nextTick(()=>{
                            this.$refs.au.play()
                        })
                    }
                })
            },
            //长按
            start(){
                var that = this;
                    this.longClick = 0;
                    this.timeOutEvent = setTimeout(function() {
                    that.longClick = 1;
                    that.downImg()
                }, 500);
            },
             // 下载
            downImg() {
                if(!window.plus) return;
                    plus.gallery.save(this.imgUrl, function () {
                        // alert('保存成功!');
                    },function(){
                        // alert('保存失败，请重试!');
                });
            },
            yuying(){
                this.$refs.au.play()
                 this.voiceShow = false
            },
            //语音识别
            isVin(e){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                
                let text = `车架号：${e}`
                this.$http.post('index.php/wechat/makeYuYin',{text}).then(res=>{
                    this.voice =res.data.data
                    toast.clear()//清除加载效果
                    
                    var u = navigator.userAgent;
                    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if(isiOS){
                        this.voiceShow = true
                    }else{
                        this.$nextTick(()=>{
                            this.$refs.au.play()
                        })
                    }
                })
            },
            //取消
            colosepopup(){
                 this.CarInfoShow = false
            },
            //直接提交
            comeWei(){
                this.CarInfoShow = false
                this.onsubmit()
            },
            //更新
            upDate(e){
                this.isData.vin = e[0]
                this.CarInfoShow = false
                this.onsubmit()
            },
            async submit (){
                if(this.isData.carNumberPlate && this.isData.vin){
                     const {data} = await PlateNum({value:this.isData.carNumberPlate}).then(res=>res)
                    let arr =  data.data
                    if(arr.length==0){
                        this.onsubmit()
                    }else if(arr.length==1){
                        if(arr[0].carNumberPlate == this.isData.carNumberPlate && arr[0].vin == this.isData.vin){
                            this.onsubmit()
                        }else{
                            this.repetition = arr
                            this.CarInfoShow = true
                            this.$nextTick(()=>{
                                this.$refs.ObtainRef.checkbox = []
                            })
                        }
                    }else if(arr.length > 1){
                        this.repetition = arr
                        this.CarInfoShow = true
                        this.$nextTick(()=>{
                                this.$refs.ObtainRef.checkbox = []
                            })
                    }
                 }else{
                      this.$toast.fail('车牌号或者车架号不能为空！')
                 }
                
            },
        
            async onsubmit(){
                try{
                     let data = encipherMent(JSON.stringify({vin:this.isData.vin,cancellation_photos:this.proveUrl}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('/index.php/vehicles_upload/saveCancellationPhotos',param).then(res=>res)
                    console.log(res)
                    if(res.code === 0 ){
                        this.gouot()
                        this.$toast.success(res.msg)
                    }else{
                        this.$toast.fail(res.msg)
                    }
                }
                catch (err){
                    // this.$toast.fail('')
                    console.log(err)
                    
                }
            },
            gouot() {
                this.$router.push('/Recognition')
            },
             deleteimg1(){
               this.proveUrl = ''
               this.fileList1 = []
               this.isData = {}
           },
             uploadershang(file){
                file.status = "uploading";
                file.message = "上传中...";
                let params = new FormData(); //创建form对象
                params.append("file", file.file);
                this.$http.post("/index.php/index/upload/index", params)
                .then((res) => {
                    console.log(res)
                if (res.data.code == 0) {
                    let url = '/index.php/vehicles_upload/cancellationPhotos'
                    this.proveUrl = res.data.data
                    this.recognition(url,{path:res.data.data})
                    file.status = "done";
                } else if(res.data.code == -1){
                    deleteCookie('token')
                    this.$toast.fail(res.data.msg)
                    this.$router.push('/')
                }else{
                    file.status = "failed";
                    file.message = "上传失败";
                    this.proveUrl=''
                    this.fileList1=[]//行驶证正面照片
                }
                }).catch(()=>{
                    this.proveUrl = ''
                     this.fileList1=[],//行驶证正面照片
                    file.status = "failed";
                    file.message = "上传失败";
                    this.$toast.fail('网络开小差了，请稍后重试！')
                })
           },
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.content-btn{
    margin: 50px 0;
}
.content{
    padding: 10px;
    // padding-top: 50px;
    // margin-top: 20px;
    text-align: center;
    .content-up{
        margin-top: 20px;
        padding: 10px;
      
    }
}
  .popup{
    width: 80%;
    padding: 20px;
    .btn-ok{
        width: 100%;
        display: flex;
        .van-button{
            flex: 1;
        }
    }
}
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>